import { format, parseISO } from "date-fns"
import { PageProps } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

interface Game {
    opponent: string
    location: string
    directions: string
    tournament: string
    start_at: Date
    schedule?: string
}

const Tournaments: React.FC<PageProps> = () => {
    const gamesComplete: Game[] = [
        {
            opponent: "Greylock Thunder",
            location: "Hoyle School - Field 1",
            start_at: parseISO("2022-07-09T08:00:00.000-04:00"),
            directions: "https://goo.gl/maps/T8EoLsHsxhgAcCRA6",
            tournament: "Bay State Showcase",
            schedule:
                "https://tourneymachine.com/Public/Results/Team.aspx?IDTournament=h20220606141014497efbaacee0f0c4f&IDDivision=h20220606141014578a8392b9a78af40&IDTeam=h202206061424459672307d4debb924f",
        },
        {
            opponent: "Polar Crush Premier 18",
            location: "Hoyle School - Field 2",
            start_at: parseISO("2022-07-09T09:45:00.000-04:00"),
            directions: "https://goo.gl/maps/T8EoLsHsxhgAcCRA6",
            tournament: "Bay State Showcase",
            schedule:
                "https://tourneymachine.com/Public/Results/Team.aspx?IDTournament=h20220606141014497efbaacee0f0c4f&IDDivision=h20220606141014578a8392b9a78af40&IDTeam=h202206061424459672307d4debb924f",
        },
        {
            opponent: "Rage Softball",
            location: "Hoyle School - Field 2",
            start_at: parseISO("2022-07-09T13:15:00.000-04:00"),
            directions: "https://goo.gl/maps/T8EoLsHsxhgAcCRA6",
            tournament: "Bay State Showcase",
            schedule:
                "https://tourneymachine.com/Public/Results/Team.aspx?IDTournament=h20220606141014497efbaacee0f0c4f&IDDivision=h20220606141014578a8392b9a78af40&IDTeam=h202206061424459672307d4debb924f",
        },
        {
            opponent: "NJ Vipers Premier",
            location: "Hoyle School - Field 4",
            start_at: parseISO("2022-07-10T09:45:00.000-04:00"),
            directions: "https://goo.gl/maps/T8EoLsHsxhgAcCRA6",
            tournament: "Bay State Showcase",
            schedule:
                "https://tourneymachine.com/Public/Results/Team.aspx?IDTournament=h20220606141014497efbaacee0f0c4f&IDDivision=h20220606141014578a8392b9a78af40&IDTeam=h202206061424459672307d4debb924f",
        },
        {
            opponent: "Valley Fusion",
            location: "Hoyle School - Field 3",
            start_at: parseISO("2022-07-10T11:30:00.000-04:00"),
            directions: "https://goo.gl/maps/T8EoLsHsxhgAcCRA6",
            tournament: "Bay State Showcase",
            schedule:
                "https://tourneymachine.com/Public/Results/Team.aspx?IDTournament=h20220606141014497efbaacee0f0c4f&IDDivision=h20220606141014578a8392b9a78af40&IDTeam=h202206061424459672307d4debb924f",
        },
        {
            opponent: "Drifters",
            location: "Swansea - Field 6",
            start_at: parseISO("2022-06-18T08:30:00.000-04:00"),
            directions: "https://goo.gl/maps/9qgCsWBCkM4ZURPv9",
            tournament: "X-treme Father's Day Tournament",
        },
        {
            opponent: "RI WAves",
            location: "Swansea - Field 6",
            start_at: parseISO("2022-06-18T11:30:00.000-04:00"),
            directions: "https://goo.gl/maps/9qgCsWBCkM4ZURPv9",
            tournament: "X-treme Father's Day Tournament",
        },
        {
            opponent: "CT Bombers",
            location: "Swansea - Field 6",
            start_at: parseISO("2022-06-18T01:15:00.000-04:00"),
            directions: "https://goo.gl/maps/9qgCsWBCkM4ZURPv9",
            tournament: "X-treme Father's Day Tournament",
        },
        {
            opponent: "Show Softball",
            location: "Chelmsford - Southwell Field River",
            start_at: parseISO("2022-06-24T09:45:00.000-04:00"),
            directions: "https://goo.gl/maps/dbMZhNuMdf5eWCT36",
            tournament: "NE College Showcase",
            schedule:
                "https://tourneymachine.com/Public/Results/Team.aspx?IDTournament=h202206011713464294e557502986e49&IDDivision=h202206011713465419e39d41ca81246&IDTeam=h202206011907293248dbf8e85946c47",
        },
        {
            opponent: "BNB Rox",
            location: "Chelmsford - Southwell Field River",
            start_at: parseISO("2022-06-24T11:30:00.000-04:00"),
            directions: "https://goo.gl/maps/dbMZhNuMdf5eWCT36",
            tournament: "NE College Showcase",
            schedule:
                "https://tourneymachine.com/Public/Results/Team.aspx?IDTournament=h202206011713464294e557502986e49&IDDivision=h202206011713465419e39d41ca81246&IDTeam=h202206011907293248dbf8e85946c47",
        },
        {
            opponent: "Metrowest Thunder 18U",
            location: "Chelmsford - Southwell Field Douglas",
            start_at: parseISO("2022-06-25T15:00:00.000-04:00"),
            directions: "https://goo.gl/maps/dbMZhNuMdf5eWCT36",
            tournament: "NE College Showcase",
            schedule:
                "https://tourneymachine.com/Public/Results/Team.aspx?IDTournament=h202206011713464294e557502986e49&IDDivision=h202206011713465419e39d41ca81246&IDTeam=h202206011907293248dbf8e85946c47",
        },
        {
            opponent: "CT Rebels",
            location: "Chelmsford - Southwell Field Douglas",
            start_at: parseISO("2022-06-25T18:30:00.000-04:00"),
            directions: "https://goo.gl/maps/dbMZhNuMdf5eWCT36",
            tournament: "NE College Showcase",
            schedule:
                "https://tourneymachine.com/Public/Results/Team.aspx?IDTournament=h202206011713464294e557502986e49&IDDivision=h202206011713465419e39d41ca81246&IDTeam=h202206011907293248dbf8e85946c47",
        },
        {
            opponent: "Capital District Fusion",
            location: "Devens - Willard Field 3",
            start_at: parseISO("2022-06-26T08:00:00.000-04:00"),
            directions: "https://goo.gl/maps/u8JKkLr34Wrk6KBU8",
            tournament: "NE College Showcase",
            schedule:
                "https://tourneymachine.com/Public/Results/Team.aspx?IDTournament=h202206011713464294e557502986e49&IDDivision=h202206011713465419e39d41ca81246&IDTeam=h202206011907293248dbf8e85946c47",
        },
    ]

    const games: Game[] = [
        {
            opponent: "KP Pride Regional",
            location: "Belmont - Field 1",
            start_at: parseISO("2022-07-22T14:00:00.000-04:00"),
            directions: "https://goo.gl/maps/H8Q4fFN8ULHTifhK9",
            tournament: "RI Bombers Elite End of Summer Showcase",
        },
        {
            opponent: "MA Legacy",
            location: "Belmont - Field 3",
            start_at: parseISO("2022-07-22T15:30:00.000-04:00"),
            directions: "https://goo.gl/maps/H8Q4fFN8ULHTifhK9",
            tournament: "RI Bombers Elite End of Summer Showcase",
        },
        {
            opponent: "Wachusett Stars",
            location: "Belmont - Field 2",
            start_at: parseISO("2022-07-23T11:00:00.000-04:00"),
            directions: "https://goo.gl/maps/H8Q4fFN8ULHTifhK9",
            tournament: "RI Bombers Elite End of Summer Showcase",
        },
        {
            opponent: "Shockwave Black",
            location: "Belmont - Field 1",
            start_at: parseISO("2022-07-22T14:00:00.000-04:00"),
            directions: "https://goo.gl/maps/H8Q4fFN8ULHTifhK9",
            tournament: "RI Bombers Elite End of Summer Showcase",
        },

        {
            opponent: "Untamed Softball",
            location: "Sharon - Field 3",
            start_at: parseISO("2022-07-29T07:30:00.000-04:00"),
            directions: "https://goo.gl/maps/ijbZZkwt1xjQmUbNA",
            tournament: "New England’s Finest Showcase",
        },
        {
            opponent: "CT Lightning",
            location: "Sharon - Field 3",
            start_at: parseISO("2022-07-29T11:00:00.000-04:00"),
            directions: "https://goo.gl/maps/ijbZZkwt1xjQmUbNA",
            tournament: "New England’s Finest Showcase",
        },

        {
            opponent: "Xtreme Chaos Gold",
            location: "Sharon - Field 3",
            start_at: parseISO("2022-07-30T12:45:00.000-04:00"),
            directions: "https://goo.gl/maps/ijbZZkwt1xjQmUbNA",
            tournament: "New England’s Finest Showcase",
        },
        {
            opponent: "CJ Lightning 18U Gold",
            location: "Sharon - Field 3",
            start_at: parseISO("2022-07-30T16:15:00.000-04:00"),
            directions: "https://goo.gl/maps/ijbZZkwt1xjQmUbNA",
            tournament: "New England’s Finest Showcase",
        },

        {
            opponent: "CT Diamond Club Select",
            location: "Sharon - Field 2",
            start_at: parseISO("2022-07-31T12:45:00.000-04:00"),
            directions: "https://goo.gl/maps/ijbZZkwt1xjQmUbNA",
            tournament: "New England’s Finest Showcase",
        },
        {
            opponent: "NE Fusion Gold",
            location: "Sharon - Field 2",
            start_at: parseISO("2022-07-31T14:30:00.000-04:00"),
            directions: "https://goo.gl/maps/ijbZZkwt1xjQmUbNA",
            tournament: "New England’s Finest Showcase",
        },
    ]

    return (
        <Layout>
            <SEO title="Tournaments" description="Summer 2022 Showcase Tournament Schedule" />
            <section className="hero is-dark mt-6">
                <div className="hero-body">
                    <div className="container">
                        <p className="title">CM Thunder Gold 18U Tournament Schedule - Summer 2022</p>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="container">
                    <div className="title has-text-centered">
                        2022 Summer Tournaments
                        <br />
                        <small>Last Updated: July 14, 2022</small>
                    </div>
                    <div className="columns">
                        <div className="column">
                            <article className="media mt-4">
                                <div className="media-content">
                                    <div className="content">
                                        <p>
                                            <a href="http://www.swanseasoftball.com/" target="_blank" rel="noreferrer">
                                                <strong>X-treme Father's Day Tournament</strong>
                                            </a>{" "}
                                            <small>June 17-19, 2022</small>
                                            <br />
                                            Swansea, MA
                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="media">
                                <div className="media-content">
                                    <div className="content">
                                        <p>
                                            <a
                                                href="https://www.polarcrushsoftball.com/our-tournament"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <strong>NE College Showcase</strong>
                                            </a>{" "}
                                            <small>June 24-26, 2022</small>
                                            <br />
                                            Devens, MA
                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="media">
                                <div className="media-content">
                                    <div className="content">
                                        <p>
                                            <a
                                                href="https://www.baystateshowcase.com/bay-state-college-showcase.cfm"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <strong>Bay State College Showcase</strong>
                                            </a>{" "}
                                            <small>July 8-10, 2022</small>
                                            <br />
                                            Taunton, MA
                                        </p>
                                    </div>
                                </div>
                            </article>
                        </div>
                        <div className="column">
                            <article className="media mt-4">
                                <div className="media-content">
                                    <div className="content">
                                        <p>
                                            <a
                                                href="http://www.bomberselitesoftball.com/elite-summer-showcase.html"
                                                target="_blank"
                                                rel="noreferrer"
                                            >
                                                <strong>Bombers Elite</strong>
                                            </a>{" "}
                                            <small>July 22-24, 2022</small>
                                            <br />
                                            Warwick, RI
                                        </p>
                                    </div>
                                </div>
                            </article>
                            <article className="media mt-4">
                                <div className="media-content">
                                    <div className="content">
                                        <p>
                                            <a href="https://www.nefinestshowcase.com/index.cfm" target="_blank" rel="noreferrer">
                                                <strong>New Englands Finest</strong>
                                            </a>{" "}
                                            <small>July 29-31, 2022</small>
                                            <br />
                                            Wrentham, MA
                                        </p>
                                    </div>
                                </div>
                            </article>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="container">
                    <div className="title has-text-centered">Summer 2022 Currently Scheduled Games</div>
                    <div className="container">
                        {games.length == 0 ? (
                            <div className="subtitle">No currently scheduled games.</div>
                        ) : (
                            <div className="table-container">
                                <table className="table is-fullwidth is-striped">
                                    <thead>
                                        <tr>
                                            <th>Opponent</th>
                                            <th>Date</th>
                                            <th>Time</th>
                                            <th className="is-hidden-mobile">Tournament</th>
                                            <th>Location</th>
                                            <th className="is-hidden-mobile">Schedule</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {games.map((game, i) => (
                                            <tr key={i}>
                                                <td>{game.opponent}</td>
                                                <td>{format(game.start_at, "PP")}</td>
                                                <td>{format(game.start_at, "p")}</td>
                                                <td className="is-hidden-mobile">{game.tournament}</td>
                                                <td>
                                                    <a href={game.directions} target="_blank" rel="noreferrer">
                                                        {game.location}
                                                    </a>
                                                </td>
                                                <td className="is-hidden-mobile">
                                                    {game.schedule && (
                                                        <a href={game.schedule} target="_blank" rel="noreferrer">
                                                            Schedule
                                                        </a>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </section>
            <section className="section">
                <div className="container">
                    <div className="title has-text-centered">Summer 2022 Completed Games</div>
                    <div className="container">
                        {gamesComplete.length == 0 ? (
                            <div className="subtitle">No currently completed games.</div>
                        ) : (
                            <div className="table-container">
                                <table className="table is-fullwidth is-striped">
                                    <thead>
                                        <tr>
                                            <th>Opponent</th>
                                            <th>Date</th>
                                            <th>Time</th>
                                            <th className="is-hidden-mobile">Tournament</th>
                                            <th>Location</th>
                                            <th className="is-hidden-mobile">Schedule</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {gamesComplete.map((game, i) => (
                                            <tr key={i}>
                                                <td>{game.opponent}</td>
                                                <td>{format(game.start_at, "PP")}</td>
                                                <td>{format(game.start_at, "p")}</td>
                                                <td className="is-hidden-mobile">{game.tournament}</td>
                                                <td>
                                                    <a href={game.directions} target="_blank" rel="noreferrer">
                                                        {game.location}
                                                    </a>
                                                </td>
                                                <td className="is-hidden-mobile">
                                                    {game.schedule && (
                                                        <a href={game.schedule} target="_blank" rel="noreferrer">
                                                            Schedule
                                                        </a>
                                                    )}
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Tournaments
